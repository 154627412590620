<template>
  <div>
    <v-alert
      v-for="(error, index) in errors"
      :key="index"
      type="error"
      class="mx-3"
      flat
      dense
    >
      {{ error }}
    </v-alert>
  </div>
</template>

<script>
export default {
  name: 'FormErrors',
  props: {
    veeErrors: {
      type: Object,
      default: () => {
      }
    },
    otherErrors: {
      type: [String, Array],
      default: () => []
    },
    otherError: {
      type: String,
      default: null
    }
  },
  computed: {
    errors () {
      const otherErrors = Array.isArray(this.otherErrors) ? this.otherErrors : [this.otherErrors]
      return new Set([...Object.values(this.veeErrors).map((content) => content).flat(), ...otherErrors])
    }
  }
}

</script>
