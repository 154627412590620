<template>
  <ValidationObserver
    ref="observer"
    v-slot="{ handleSubmit, errors, dirty, invalid }"
  >
    <v-form
      :disabled="loading||submitting"
      @submit.prevent="handleSubmit(handleSubmitHandler)"
    >
      <v-card :loading="loading || submitting">
        <v-card-title
          class="title"
          style="text-transform: capitalize"
        >
          Create {{ categoryName }} Category
        </v-card-title>
        <v-card-text class="pt-0">
          <ValidationProvider
            v-slot="{ errors }"
            name="Name"
            rules="required"
          >
            <v-text-field
              v-model="category.name"
              required
              label="Name"
              :error-messages="errors"
              placeholder="Give the category a short name"
            />
          </ValidationProvider>
          <ValidationProvider
            v-slot="{ errors }"
            name="Notes"
            rules="required"
          >
            <v-textarea
              v-model="category.notes"
              rows="3"
              required
              :error-messages="errors"
              label="Notes"
            />
          </ValidationProvider>
          <v-row>
            <FormErrors
              :vee-errors="errors"
            />
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn
            class="mt-4"
            @click="cancel"
          >
            Cancel
          </v-btn>
          <v-btn
            class="mt-4"
            color="primary"
            type="submit"
            :disabled="invalid || !dirty"
          >
            {{ category._id ? 'Save' : 'Create' }}
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </ValidationObserver>
</template>

<script>
import FormErrors from '@/components/form/FormErrors.vue'
import { ValidationObserver, ValidationProvider } from 'vee-validate'

export default {
  name: 'GeneralCategoryForm',
  components: { FormErrors, ValidationObserver, ValidationProvider },
  props: {
    categoryName: {
      type: String,
      required: true
    },
    id: {
      type: String,
      default: null
    },
    restEndPoint: {
      type: String,
      required: true
    },
    returnRoute: {
      type: String,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      submitting: false,
      category: {
        _id: null,
        name: '',
        notes: ''
      }
    }
  },
  async created () {
    this.loading = true
    if (this.id) {
      const { data: category } = await this.$axios.get(`${this.restEndPoint}/${this.id}`)
      this.category = category
    }
    this.loading = false
  },
  methods: {
    async createCategory () {
      await this.$axios.post(`${this.restEndPoint}`, { ...this.category, defaultValue: this.category.value })
      this.$emit('created')
    },
    async updateCategory () {
      await this.$axios.put(`${this.restEndPoint}/${this.category._id}`, {
        ...this.category,
        defaultValue: this.category.value
      })
      this.$emit('updated')
      await this.$router.push(`${this.returnRoute}`)
    },
    async cancel () {
      if (this.category._id) {
        await this.$router.push(this.returnRoute)
      } else {
        this.resetForm()
        this.$emit('canceled')
      }
    },
    resetForm () {
      this.$refs.observer.reset()
      this.category = { _id: null, name: '', notes: '' }
    },
    async handleSubmitHandler () {
      this.submitting = true
      try {
        if (this.category._id) {
          await this.updateCategory()
        } else {
          await this.createCategory()
        }
        this.resetForm()
      } catch (err) {
        if (err.response.status === 400) {
          await this.$store.dispatch('showSnackbar', { color: 'error', text: err.response.data.message })
        }
        throw err
      } finally {
        this.submitting = false
      }
    }
  }
}
</script>

<style lang="scss" scoped>

</style>
