var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function(ref){
var handleSubmit = ref.handleSubmit;
var errors = ref.errors;
var dirty = ref.dirty;
var invalid = ref.invalid;
return [_c('v-form',{attrs:{"disabled":_vm.loading||_vm.submitting},on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.handleSubmitHandler)}}},[_c('v-card',{attrs:{"loading":_vm.loading || _vm.submitting}},[_c('v-card-title',{staticClass:"title",staticStyle:{"text-transform":"capitalize"}},[_vm._v(" Create "+_vm._s(_vm.categoryName)+" Category ")]),_c('v-card-text',{staticClass:"pt-0"},[_c('ValidationProvider',{attrs:{"name":"Name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"required":"","label":"Name","error-messages":errors,"placeholder":"Give the category a short name"},model:{value:(_vm.category.name),callback:function ($$v) {_vm.$set(_vm.category, "name", $$v)},expression:"category.name"}})]}}],null,true)}),_c('ValidationProvider',{attrs:{"name":"Notes","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-textarea',{attrs:{"rows":"3","required":"","error-messages":errors,"label":"Notes"},model:{value:(_vm.category.notes),callback:function ($$v) {_vm.$set(_vm.category, "notes", $$v)},expression:"category.notes"}})]}}],null,true)}),_c('v-row',[_c('FormErrors',{attrs:{"vee-errors":errors}})],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{staticClass:"mt-4",on:{"click":_vm.cancel}},[_vm._v(" Cancel ")]),_c('v-btn',{staticClass:"mt-4",attrs:{"color":"primary","type":"submit","disabled":invalid || !dirty}},[_vm._v(" "+_vm._s(_vm.category._id ? 'Save' : 'Create')+" ")])],1)],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }